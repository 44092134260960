import { action, makeAutoObservable, observable } from 'mobx';

import type {
  InsuranceProduct,
  InitOrderResponse,
  InitOrderError,
  Utm,
} from '@shared/types';

type InitStateType = Partial<InsuranceProduct>;

export class InitProductStore {
  initState: InitStateType = {}; // Начальные свойства объекта при инициализации приложения
  regUtm?: Utm = undefined; // Метки передаваемые в урл (аналитика)
  initOrder?: InitOrderResponse = undefined; // Данные после успешного ответа от init-order
  initOrderError?: InitOrderError = undefined; // Данные после не успешного ответа от init-order
  formBuilderIsMounted = false; // Признак монтирования форм билдера, чтобы задавать начальные данные формы после монтирования
  isInitSessionRecovery = true; // Флаг, указывающий на необходимость запуска восстановления сессии

  constructor() {
    makeAutoObservable(this, {
      initState: observable,
      setInitState: action,
      initOrder: observable,
      setInitOrder: action,
      regUtm: observable,
      setRegUtm: action,
      formBuilderIsMounted: observable,
      setFormBuilderIsMounted: action,
    });
  }

  setInitState = (e: InitStateType) => {
    this.initState = {
      ...e,
    };
  };

  setRegUtm = (e: Utm) => {
    this.regUtm = e;
  };

  setInitOrder = (e: InitOrderResponse | undefined) => {
    this.initOrder = e;
  };

  setInitOrderError = (e: InitOrderError | undefined) => {
    this.initOrderError = e;
  };

  setFormBuilderIsMounted = () => {
    this.formBuilderIsMounted = true;
  };

  setIsInitSessionRecovery = (e: boolean) => {
    this.isInitSessionRecovery = e;
  };
}

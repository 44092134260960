import { B2P } from '@entities/import-smart-components/b2p';
import { SmartComponentName } from '@product-config/constants';
import {
  scrollToIframe,
  type SmartComponentAdapterProps,
} from '@pulse-smart-components-kit/common';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { type Ref, Suspense, forwardRef, useCallback } from 'react';

import type { B2PValues } from '@smart-components/index';

export const B2PAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
          fieldState,
        }: SmartComponentAdapterProps<Partial<B2PValues>>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            authStore: { disableForm },
            applicationStore: { setIsPaymentStep },
            initProductStore: { setInitOrder, setInitOrderError },
            formStateStore: {
              formState: { CheckPolicy },
              updateFormValue,
            },
          },
        } = useStores();

        const hidePaymentComponent = useCallback(() => {
          updateFormValue(SmartComponentName.B2P, {
            initOrder: undefined,
            showInitOrder: false,
            isValid: true,
          });
          setIsPaymentStep(false);
        }, [updateFormValue, setIsPaymentStep]);

        const handleSubmit = useCallback(() => {
          setInitOrder(undefined);
          setInitOrderError(undefined);
          updateFormValue(SmartComponentName.CHECK_POLICY, {
            ...CheckPolicy,
            ...{ acceptRequirements: false },
          });
          hidePaymentComponent();
          scrollToIframe();
        }, [
          CheckPolicy,
          setInitOrder,
          updateFormValue,
          hidePaymentComponent,
          scrollToIframe,
        ]);

        return (
          <Suspense fallback={<FallbackSkeleton height={0} transparent />}>
            <B2P
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              fieldState={fieldState}
              disabled={disableForm}
              options={{ handleSubmit }}
            />
          </Suspense>
        );
      }
    )
  )
);

B2PAdapter.displayName = 'B2PAdapter';

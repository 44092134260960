export const i18nDefaultValues = {
  CheckPolicyAdapter: {
    headers: {
      policyholder: 'Страхователь',
      carOwner: 'Владелец автомобиля',
      driver: 'Водитель {{number}}',
      driverLicense: 'Водительское удостоверение',
      policyStartDate: 'Дата начала действия',
    },
    labels: {
      experience: 'стаж с {{yearOfStart}} г.',
      licenseDate: 'выдано {{date}}',
    },
    carDataView: {
      description: '{{year}} г.в., {{power}} л.c.',
    },
    totalPrice: {
      header: 'Общая страховая сумма',
      region: 'Регион использования ТС',
      repair: 'Ремонт',
      franchise: 'Франшиза виновника',
    },
    insuranceRisks: {
      header: 'Страховые риски и опции',
    },
    loader: {
      title: 'Проверяем ваши данные',
      subTitle: 'Обычно это занимает до 10 секунд',
    },
  },
};

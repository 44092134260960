import { ErrorCode } from '@pulse-web-platform-core/constants';
import { isAxiosError } from 'axios';
import { useEffect } from 'react';

import type { ResponseError } from '@pulse-web-platform-core/types';

import { useStores } from './use-stores';

interface Props {
  error: ResponseError | null;
}

export const useHandleNotUniqueEmail = ({ error }: Props) => {
  const {
    MainStore: {
      applicationStore: { setActiveStep },
      productStore: { setIsEmailNotUnique },
    },
  } = useStores();

  useEffect(() => {
    if (isAxiosError(error)) {
      const status = error.response?.status;
      const code = error.response?.data?.code;

      if (status === 400 && code === ErrorCode.NOT_UNIQUE_EMAIL) {
        setActiveStep(4);
        setIsEmailNotUnique(true);
      }
    }
  }, [error]);
};

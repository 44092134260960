import { SmartComponentName } from '@product-config/constants';
import { phoneDisplayValueExtCasting } from '@pulse-web-platform-core/utils';

import type { UserAuthWithPassportValues } from '@pulse-smart-components-kit/user-auth-with-passport';
import type { FormBuilderUpdateHandler } from '@shared/types';

type UpdateFormByMobileAppType = (
  handler: FormBuilderUpdateHandler,
  deviceType: string,
  phoneFromMobileApp: string,
  userAuthWuthPassport: UserAuthWithPassportValues | undefined
) => void;

export const updateFormByMobileApp: UpdateFormByMobileAppType = (
  updateFormValue,
  deviceType,
  phoneFromMobileApp,
  userAuthWuthPassport
) => {
  if (deviceType && phoneFromMobileApp) {
    const formattedPhone = phoneDisplayValueExtCasting(phoneFromMobileApp)
      .trim()
      .slice(2);

    updateFormValue(SmartComponentName.USER_AUTH_WITH_PASSPORT, {
      ...userAuthWuthPassport,
      client: true,
    });
    updateFormValue(SmartComponentName.USER_AUTH_WITH_PASSPORT, {
      ...userAuthWuthPassport,
      phone: formattedPhone,
    });
  }
};

import { useMemo } from 'react';

import type { VehicleRepairType } from '../queries';

export const useGetRepairs = (
  vehicleRepairTypes: VehicleRepairType[] | undefined
) =>
  useMemo(() => {
    if (!vehicleRepairTypes) {
      return [];
    }

    return vehicleRepairTypes?.map((item) => ({
      label: item.name,
      value: item.code,
      id: item.code,
      describe: item.description,
    }));
  }, [vehicleRepairTypes]);

import { formattingPrice } from '@pulse-web-platform-core/utils';
import { useMemo } from 'react';

import type { Franchise } from '../queries';

import { DEFAULT_FRANCHISE_OPTION } from '../constants';

export const useGetFranchises = (franchises: Franchise[] | undefined) =>
  useMemo(() => {
    if (!franchises) {
      return [DEFAULT_FRANCHISE_OPTION];
    }

    const result = franchises.map((item: Franchise) => ({
      label: formattingPrice(Number(item.name)),
      value: item.code,
    }));

    return [DEFAULT_FRANCHISE_OPTION, ...result];
  }, [franchises]);

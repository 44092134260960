import { useEffect } from 'react';

import type { ThemeType } from '@pulse-web-ui/theme';

export const useFontLink = (fontLink: string, theme: ThemeType) => {
  useEffect(() => {
    if (theme.common['font-url'] && fontLink) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `${fontLink}/${theme.common['font-url']}/font.css`;
      document.head.appendChild(link);
    } else if (theme.common['font-family']) {
      const headerStyles = [...document.styleSheets].filter(
        (item) =>
          item.href ===
          `https://fonts.googleapis.com/css?family=${encodeURI(
            theme.common['font-family']
          )}`
      );

      if (headerStyles.length === 0) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `${window.envUrls.STORAGE_URL}/common-assets/rgs/fonts/GolosText/font.css?&display=swap`;
        document.head.appendChild(link);
      }
    }
  }, [fontLink, theme.common['font-url'], theme.common['font-family']]);
};

import { analyticEvents, useSendSingleAnalyticEvent } from '@app/web-analytic';
import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import type { SmartComponentName } from '@product-config/constants';
import type { TBusCallback } from '@pulse-web-platform-core/event-bus';
import type {
  FormBuilderStructure,
  FormBuilderUpdateHandler,
  PartialFormStateValue,
} from '@shared/types';

import { FormBuilderStepItem } from './form-builder-step-item';
import { useDisabledSubmit, useScrollToElementWithError } from '../hooks';
import { useSynchronizeStore } from '../hooks/use-synchronize-store';
import {
  FormBuilderEventBus,
  FormBuilderAction,
  getDefaultValues,
} from '../utils';

interface Props {
  config: FormBuilderStructure;
}

type FormHandlerErrorParams = { fieldName: SmartComponentName; err: string };
type FormHandlerParams = {
  fieldName: SmartComponentName;
  value: PartialFormStateValue;
};
type FormHandlerObject = (params: FormHandlerParams) => void;
type FormErrorHandler = (params: FormHandlerErrorParams) => void;

export const FormBuilderStep = withMemo(
  observer(({ config }: Props) => {
    const {
      MainStore: {
        applicationStore: {
          currentStep,
          activeStep,
          wantNextStep,
          setWantNextStep,
          setCurrentStep,
          isResetFormState,
          setDisabledSubmit,
          setIsResetFormState,
        },
        formStateStore: { setFormState, formState: storeFormState },
        authStore: { setDisableForm, handleAuth },
        initProductStore: { setFormBuilderIsMounted },
      },
    } = useStores();

    const defaultValues = useMemo(
      () => getDefaultValues(config, storeFormState),
      [config, storeFormState]
    );

    const {
      control,
      reset,
      watch,
      setValue,
      getValues,
      setError,
      clearErrors,
      handleSubmit,
      formState: {
        isDirty,
        isSubmitting,
        isSubmitSuccessful,
        errors,
        submitCount,
      },
    } = useForm({ values: defaultValues });

    const elementRefs = useScrollToElementWithError(submitCount, errors);

    useSynchronizeStore(watch, setFormState, clearErrors);

    useSendSingleAnalyticEvent(isDirty, analyticEvents.start);

    useEffect(() => {
      if (isResetFormState) {
        reset(storeFormState);
        setIsResetFormState(false);
      }
    }, [isResetFormState]);

    useEffect(() => {
      setCurrentStep(activeStep);
    }, [activeStep]);

    useEffect(() => {
      setFormBuilderIsMounted();
    }, []);

    const updateFormHandlerObject: FormHandlerObject = useCallback(
      ({ fieldName, value }) => {
        updateFormHandler(fieldName, value);
      },
      []
    );

    const updateFormHandler: FormBuilderUpdateHandler = useCallback(
      (fieldName, value) => {
        getValues();
        setValue(fieldName, value);
      },
      []
    );

    const updateFormErrorHandler: FormErrorHandler = useCallback(
      ({ fieldName, err }) => {
        setError(fieldName, { message: err });
        setDisableForm(false);
      },
      []
    );

    const handleAuthUser: TBusCallback<{ phone: string }> = useCallback(
      ({ phone }) => {
        handleAuth(phone);
      },
      [handleAuth, currentStep]
    );

    useEffect(() => {
      const unsubscribe = FormBuilderEventBus.subscribe(
        FormBuilderAction.SUBMIT_FORM,
        handleSubmit(() => {})
      );

      return unsubscribe;
    }, [handleSubmit]);

    useEffect(() => {
      const unsubscribe = FormBuilderEventBus.subscribe(
        FormBuilderAction.UPDATE_FORM,
        updateFormHandlerObject
      );

      return unsubscribe;
    }, [updateFormHandlerObject]);

    useEffect(() => {
      const unsubscribe = FormBuilderEventBus.subscribe<FormHandlerErrorParams>(
        FormBuilderAction.UPDATE_FORM_ERRORS,
        updateFormErrorHandler
      );

      return unsubscribe;
    }, [updateFormErrorHandler]);

    useEffect(() => {
      const unsubscribe = FormBuilderEventBus.subscribe<{ phone: string }>(
        FormBuilderAction.AUTH_USER,
        handleAuthUser
      );

      return unsubscribe;
    }, [handleAuthUser]);

    const disabledSubmit = useDisabledSubmit({
      errors,
      submitCount,
      wantNextStep,
    });

    useEffect(() => {
      setDisabledSubmit(disabledSubmit);
    }, [disabledSubmit]);

    useEffect(() => {
      if (isSubmitSuccessful && !isSubmitting) {
        setWantNextStep(true);
      }
    }, [isSubmitSuccessful, isSubmitting]);

    const setRefHandler = useCallback(
      (e: HTMLDivElement, name: string) => (elementRefs.current[name] = e),
      []
    );

    return config.body
      .filter((item) => item?.step === activeStep)
      .map((component) => (
        <FormBuilderStepItem
          setRef={setRefHandler}
          key={`${activeStep}${component.props.id}`}
          component={component}
          isSubmitting={isSubmitting}
          control={control}
        />
      ));
  })
);

FormBuilderStep.displayName = 'FormBuilderStep';

import { TechnicalScreen } from '@pulse-web-ui/technical-screen';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

const RGSNoWIFI = lazy(
  () => import('@pulse-web-ui/icons/lib/lazy/rgs-no-wifi')
);

export const GlobalError = observer(() => {
  const {
    MainStore: {
      errorStore: { hasError, refetch },
    },
  } = useStores();
  const { t } = useTranslation();

  if (!hasError) return;

  const errorHandler = () => {
    if (refetch instanceof Function) {
      refetch();
    }
  };

  return (
    <TechnicalScreen
      title={t('COMMON:headers.globalError')}
      buttonLabel={t('COMMON:labels.tryAgain')}
      onButtonClick={errorHandler}
      isShow={hasError}
      icon={
        <Suspense>
          <RGSNoWIFI width={80} />
        </Suspense>
      }
    />
  );
});

import type {
  ContractDataType,
  UpdateProfileResponse,
} from '@pulse-web-platform-core/types';
import type { GetSubmitOrderResponse } from '@shared/types';

export const getInitOrderData = (
  resSubmitOrder: GetSubmitOrderResponse | undefined,
  profile: UpdateProfileResponse | undefined
): ContractDataType => ({
  amount: resSubmitOrder?.premiumAndDelta,
  contractId: resSubmitOrder?.contractId,
  contractNumber: resSubmitOrder?.contractNumber,
  email: profile?.profile?.email,
  firstName: profile?.profile?.firstName,
  lastName: profile?.profile?.lastName,
  patronymic: profile?.profile?.middleName,
  operationType: 'ORDER',
  orderNumber: resSubmitOrder?.orderNumber,
  paymentDocumentId: resSubmitOrder?.billId,
  subscriptionId: resSubmitOrder?.subscriptionId,
  subscriptionType: resSubmitOrder?.subscriptionType,
});

import {
  importCommonComponents,
  importSmartComponents,
} from '@entities/import-smart-components';
import {
  CascoStep,
  type ConfigCommonComponent,
  type ProductStep,
  type StepConfig,
} from '@product-config/index';

import type { HeaderStep } from '@pulse-smart-components-kit/header-base';
import type {
  CommonComponent,
  FormBuilderStructure,
  SmartComponent,
} from '@shared/types';

export const initState: FormBuilderStructure = {
  footer: [],
  body: [],
  submit: [],
  header: [],
};

export const getCommonComponents = (
  array: ConfigCommonComponent[]
): CommonComponent[] =>
  array
    .filter((component) => !!importCommonComponents[component.name])
    .map((component) => {
      return {
        component: importCommonComponents[component.name],
        props: {
          name: component.name,
          id: component.id,
        },
      };
    });

type FlowStoreConfig = {
  components: SmartComponent[];
  header: HeaderStep;
  steps: StepConfig;
};

export const getSmartComponents = (
  { components, name, icon, additionalVariables }: ProductStep,
  flowIndex: number
): FlowStoreConfig => ({
  steps: {
    step: flowIndex + 1,
    value: { ...additionalVariables },
  },
  header: {
    icon,
    name,
  },
  components: components
    .filter((component) => !!importSmartComponents[component.name])
    .map((component) => {
      const { value, ...options } = component?.additionalVariables || {};

      return {
        component: importSmartComponents[component.name],
        step: flowIndex + 1,
        props: {
          name: component.name,
          id: component.id,
          value: {
            ...value,
          },
          options,
        },
      };
    }),
});

export const mergeAgentHeaderConfig = (
  headerConfig: HeaderStep[],
  agentLogin?: string
) => {
  if (!agentLogin) return headerConfig;

  const agentPaymentStep: HeaderStep = {
    name: CascoStep.FORMALIZATION,
    icon: 'DocumentFull',
  };

  const agentClientStep: HeaderStep = {
    name: CascoStep.CLIENTS,
    icon: 'Neighbors',
  };

  return headerConfig.map((step) => {
    switch (step.name) {
      case CascoStep.PAYMENT:
        return agentPaymentStep;
      case CascoStep.FORMALIZATION:
        return agentClientStep;
      default:
        return step;
    }
  });
};

import {
  FormBuilderAction,
  FormBuilderEventBus,
} from '@features/form-builder/utils';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';

import type {
  FormBuilderUpdateHandler,
  FormState,
  PartialFormState,
} from '@shared/types';

export class FormStateStore {
  formState: FormState = {}; // Форма со смарт компонентами и их базовыми свойствами
  formString: string = ''; // Форма смарт компонентов в виде строки, используется в bff
  initFormState?: PartialFormState = undefined; // Начальное состояние формы для сброса после успешной покупки

  constructor() {
    makeAutoObservable(this, {
      formState: observable,
      setFormState: action,
      formString: observable,
      clearFormState: action,
      initFormState: observable,
      setInitFormState: action,
    });
  }

  setFormState = (e: PartialFormState) => {
    runInAction(() => {
      this.formState = {
        ...(e as Partial<FormState>),
      };
      this.formString = JSON.stringify(e);
    });
  };

  clearFormState = () => {
    this.formState = {};
  };

  setInitFormState = (e: PartialFormState) => {
    this.initFormState = e;
  };

  updateFormValue: FormBuilderUpdateHandler = (fieldName, value) => {
    FormBuilderEventBus.publish(FormBuilderAction.UPDATE_FORM, {
      fieldName,
      value,
    });
  };
}

import { useMemo } from 'react';

import type { VehicleRegion } from '../queries';

export const useGetRegions = (vehicleRegions: VehicleRegion[] | undefined) =>
  useMemo(() => {
    if (!vehicleRegions) {
      return [];
    }

    return vehicleRegions.map((item) => ({
      label: item.name,
      value: item.code,
    }));
  }, [vehicleRegions]);

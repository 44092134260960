import { Spinner } from '@pulse-web-ui/spinner';
import {
  StyledThemeProvider,
  rgsOnlineTheme,
  createGlobalStyle,
} from '@pulse-web-ui/theme';
import { THEME_FOLDER_NAME } from '@shared/constants';
import { useStores, useThemeRequest } from '@shared/hooks';
import merge from 'lodash.merge';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';

import type { Dictionary } from '../types';
import type { CascoProductConfig } from '@product-config/index';
import type { AuthFlow, ThemeConfig } from '@pulse-web-platform-core/types';
import type { ThemeType } from '@pulse-web-ui/theme';

import { useDictionary, useFontLink } from './hooks';
import { FontContainer } from './theme-container.styles';
import { getThemeLink, getThemeURL } from './utils';

type Props = {
  themeUrl?: string;
  children: React.ReactNode;
};

type GlobalThemeProps = {
  theme: ThemeType;
};

export const ThemeContainerIn = observer(({ children, themeUrl }: Props) => {
  // themeUrl - составной параметр вида “partnername_customname”
  // первая часть partnername - название партнера (соответствующий каталог в CDN)
  // вторая часть customname - название конфига кастомизации (внутри на первом этапе два параметра с названием темы ui и названием коллекции текстов texts)
  // подробнее - в документации

  const [partnername, customname] = themeUrl?.split('_') || [];
  const themeLink = getThemeLink(partnername, customname);
  const {
    MainStore: {
      applicationStore: { setLoading, loading },
      themesStore: { setAppConfig },
    },
  } = useStores();

  const fontLink = partnername
    ? `${window.envUrls.STORAGE_URL}/${THEME_FOLDER_NAME}/${String(partnername)}/fonts`
    : '';

  const { isLoading: isLoadingConfig, res: resConfig } =
    useThemeRequest<ThemeConfig>(
      'themingRequest',
      themeLink,
      [themeLink],
      !!themeLink
    );

  const { isLoading: isLoadingTheme, res: resTheme } =
    useThemeRequest<ThemeType>(
      'theme',
      getThemeURL(resConfig, partnername, 'ui', 'theme-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'ui', 'theme-url')
    );

  const { isLoading: isLoadingIcons, res: resIcons } =
    useThemeRequest<AuthFlow>(
      'icons',
      getThemeURL(resConfig, partnername, 'icons', 'icons-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'icons', 'icons-url')
    );

  const { isLoading: isLoadingTexts, res: resTexts } =
    useThemeRequest<Dictionary>(
      'texts',
      getThemeURL(resConfig, partnername, 'i18n', 'texts-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'i18n', 'texts-url')
    );

  const { isLoading: isLoadingFlow, res: resFlow } =
    useThemeRequest<CascoProductConfig>(
      'flow',
      getThemeURL(resConfig, partnername, 'flows', 'flow-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'flows', 'flow-url')
    );

  useEffect(() => {
    if (!isLoadingFlow && resFlow) setAppConfig(resFlow);
  }, [isLoadingFlow, resFlow]);

  const customTheme = useMemo(() => {
    if (!loading && resTheme) {
      return merge(rgsOnlineTheme, resTheme, resIcons || {});
    }

    return rgsOnlineTheme;
  }, [loading, resTheme, resIcons]);

  useFontLink(fontLink, customTheme);

  const GlobalStyle = createGlobalStyle`
    body {
      ${({ theme }: GlobalThemeProps) => {
        if (theme.common['font-url'])
          return `
          * {
            font-family: ${theme.common['font-url']}
          }`;
        else if (theme.common['font-family']) {
          return `
          * {
            font-family: ${theme.common['font-family']}
          }`;
        }
        return '';
      }};
    };
  `;

  useEffect(() => {
    setLoading(!!themeUrl);
  }, [themeUrl]);

  useEffect(() => {
    const loading =
      isLoadingConfig ||
      isLoadingTheme ||
      isLoadingIcons ||
      isLoadingTexts ||
      isLoadingFlow;

    setLoading(loading);
  }, [
    isLoadingConfig,
    isLoadingTheme,
    isLoadingIcons,
    isLoadingTexts,
    isLoadingFlow,
  ]);

  useDictionary(resTexts, isLoadingTexts);

  if (loading) {
    return (
      <StyledThemeProvider theme={rgsOnlineTheme}>
        <Spinner />
      </StyledThemeProvider>
    );
  }

  return (
    <StyledThemeProvider theme={customTheme}>
      <GlobalStyle />
      <FontContainer>{children}</FontContainer>
    </StyledThemeProvider>
  );
});

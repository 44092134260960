import { THEME_FOLDER_NAME } from '@shared/constants';

import { useStorageQuery } from './use-storage-query';

export const useThemeRequest = <T>(
  key: string,
  url: string,
  deps?: unknown[],
  enabled?: boolean
) => {
  const queryResult = useStorageQuery<T>(
    key,
    `/${THEME_FOLDER_NAME}${url}`,
    deps,
    {
      enabled: !!enabled,
      staleTime: 100_000_000,
    }
  );

  const { isLoading, error, data, refetch, isFetching, isRefetching, status } =
    queryResult;

  let res;

  if (!isLoading && data?.data) {
    res = data.data;
  }

  return {
    isLoading,
    error,
    res,
    refetch,
    isFetching,
    isRefetching,
    status,
  };
};

import amplitude from 'amplitude-js';
import ym from 'react-yandex-metrika';

import { AnalyticsType } from '../constants';
import { adriverEvent } from './adriver-event';
import { salesNinja } from './sales-ninja';

const sendEventToAmplitude = (
  triggerEvent: string,
  params?: object
): (() => void) => {
  const timeoutId = setTimeout(() => {
    amplitude
      .getInstance()
      .logEvent(triggerEvent, { ...params, flow_version: 'base' });
  }, 250);

  return () => clearTimeout(timeoutId);
};

export const sendAnalyticEvent = (
  triggerEvent: string,
  params?: object,
  type?: AnalyticsType
): void => {
  switch (type) {
    case AnalyticsType.myTracker:
      const _tmr = window._tmr || (window._tmr = []);
      _tmr.push({
        type: 'reachGoal',
        id: Number(window.envKeys.MY_TRACKER_KEY),
        goal: triggerEvent,
      });
      break;
    case AnalyticsType.adriver:
      adriverEvent(
        {
          sid: `${Number(window.envKeys.ADRIVER_KEY)}`,
          bt: `${Number(window.envKeys.ADRIVER_KEY2)}`,
          sz: triggerEvent,
        },
        { id: '', gid1: '', yid1: '' }
      );
      break;
    case AnalyticsType.salesNinja:
      salesNinja(triggerEvent);
      break;
    default:
      ym('reachGoal', triggerEvent, params);
      sendEventToAmplitude(triggerEvent, params);
  }
};

import {
  AddressType,
  PASSPORT_CARD_TYPE,
} from '@pulse-web-platform-core/constants';
import {
  getFormattedDate,
  getPassportValues,
} from '@pulse-web-platform-core/utils';

import type { UserAuthWithPassportValues } from '@pulse-smart-components-kit/user-auth-with-passport';
import type { RegistrationRequestData } from '@shared/queries';
import type { Utm } from '@shared/types';

interface GetRegistrationDataFn {
  userAuthWithPassport?: UserAuthWithPassportValues;
  regUtm?: Utm;
  promoAgreement: boolean;
}
export const getRegistrationData = ({
  userAuthWithPassport,
  regUtm,
  promoAgreement,
}: GetRegistrationDataFn): RegistrationRequestData => {
  const extendedIntermediaryChannelCode = `${regUtm?.media_source ?? '0000'},${
    regUtm?.campaign ?? 'WEB'
  }`;

  const userPolicies = [
    { approvedAt: new Date(), code: '003' },
    { approvedAt: new Date(), code: '005' },
    ...(promoAgreement ? [{ approvedAt: new Date(), code: '002' }] : []),
  ];

  const registrationData: RegistrationRequestData = {
    user: {
      userPolicies,
      extendedIntermediaryChannelCode,
      firstName: userAuthWithPassport?.name?.trim() ?? '',
      lastName: userAuthWithPassport?.surname?.trim() ?? '',
      middleName: userAuthWithPassport?.middlename?.trim() ?? '',
      birthDay: userAuthWithPassport?.birthday
        ? getFormattedDate(userAuthWithPassport?.birthday)
        : '',
      passport: {
        cardType: PASSPORT_CARD_TYPE,
        cardSeries: userAuthWithPassport?.passport
          ? getPassportValues(userAuthWithPassport?.passport)[0]
          : '',
        cardNumber: userAuthWithPassport?.passport
          ? getPassportValues(userAuthWithPassport?.passport)[1]
          : '',
        cardIssueDate: userAuthWithPassport?.passportDate
          ? getFormattedDate(userAuthWithPassport?.passportDate)
          : '',
        cardIssuerCode: userAuthWithPassport?.passportCode
          ? userAuthWithPassport?.passportCode.trim().split('-').join('')
          : '',
        cardIssuerName: userAuthWithPassport?.passportByWho?.trim() ?? '',
        addresses: [
          {
            addressType: AddressType.REGISTRATION,
            address: userAuthWithPassport?.address?.value?.trim() ?? '',
            addressCode: userAuthWithPassport?.address?.fias_id ?? '',
          },
        ],
      },
      email: userAuthWithPassport?.email ?? '',
      clientCategory: 'STANDART',
      utmSource: regUtm?.utm_source ?? '',
      utmMedium: regUtm?.utm_medium ?? '',
      utmCampaign: regUtm?.utm_campaign ?? '',
      utmContent: regUtm?.utm_content ?? '',
      wmId: regUtm?.wm_id ?? '',
    },
  };

  return registrationData;
};

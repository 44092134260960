import { DataView } from '@pulse-web-ui/data-view';
import { useTheme } from '@pulse-web-ui/theme';
import { lazy, Suspense } from 'react';

import { LeftSlotContent } from './car-data-view.styles';

const Auto = lazy(() => import('@pulse-web-ui/icons/lib/lazy/auto'));

interface CarDataViewProps {
  title: string;
  description: string;
}

export const CarDataView = ({ title, description }: CarDataViewProps) => {
  const theme = useTheme();

  return (
    <DataView
      data-testid="car-data-view"
      title={title}
      leftSlotContent={
        <LeftSlotContent>
          <Suspense>
            <Auto color={theme.colors.icon.primary} />
          </Suspense>
        </LeftSlotContent>
      }
      description={description}
    />
  );
};

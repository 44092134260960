import { DOCUMENT_QUERY_KEY } from '@pulse-web-platform-core/constants';
import { downloadBlob, openPdf } from '@pulse-web-platform-core/utils';

import type {
  OnDocumentLoadHandler,
  GetQueryKey,
} from './use-handle-download-pdf.types';
import type { DocumentStatusType } from '@pulse-web-ui/documents-list';

export const initDocumentsStatusList = (
  length: number = 0
): DocumentStatusType[] =>
  Array(length).fill({ isError: false, isLoading: false });

export const getQueryKey: GetQueryKey = (name) =>
  `${DOCUMENT_QUERY_KEY}-${name}`;

const handleDownloadBlob = (data: string, fileName: string) => {
  const blob = new Blob([data], {
    type: 'application/pdf',
  });

  downloadBlob(blob, fileName);
};

export const onDocumentLoadHandler: OnDocumentLoadHandler = ({
  data,
  name,
  isIOS,
}) => {
  const fileName = `${name}.pdf`;

  if (!isIOS) {
    openPdf(data.data, fileName);
  } else {
    handleDownloadBlob(data.data, fileName);
  }
};

import { useStores } from '@shared/hooks';
import { addDays } from 'date-fns';
import { useMemo } from 'react';

import type { InsuranceProductPeriod } from '../queries';

import { getMaxCountDays, getMinCountDays } from '../utils';

const TODAY_DATE = new Date();

export const useGetPeriod = (
  insuranceProductPeriods: InsuranceProductPeriod[] | undefined
) => {
  const {
    MainStore: {
      formStateStore: {
        formState: { CarSearch },
      },
      initProductStore: {
        initState: { extraParams },
      },
    },
  } = useStores();

  const minDate = useMemo(() => {
    const minCountDays = getMinCountDays(extraParams);
    return addDays(TODAY_DATE, minCountDays);
  }, [extraParams]);

  const periods = useMemo(() => {
    if (!insuranceProductPeriods) {
      return [];
    }

    return insuranceProductPeriods.map((item) => ({
      label: item.name,
      value: item.code,
    }));
  }, [insuranceProductPeriods]);

  const period = useMemo(() => {
    const maxCountDays = getMaxCountDays({
      hasKasko: Boolean(CarSearch?.haveValidPolicy),
      defaultProductValues: extraParams,
    });

    return {
      maxDate: addDays(TODAY_DATE, maxCountDays),
      minDate: minDate,
      periods: periods,
    };
  }, [CarSearch?.haveValidPolicy, extraParams, minDate, periods]);

  return period;
};

import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useController } from 'react-hook-form';

import type { FormState, SmartComponent } from '@shared/types';
import type { FC } from 'react';
import type { Control, FieldValues } from 'react-hook-form';

interface Props {
  component: SmartComponent;
  control: Control<FieldValues, FormState[keyof FormState]>;
  isSubmitting: boolean;
  setRef: (e: HTMLDivElement, name: string) => void;
}

const rules = {
  validate: (value: FormState[keyof FormState]) => Boolean(value?.isValid),
};

export const FormBuilderStepItem: FC<Props> = withMemo(
  observer(
    ({
      component: { component: Component, props },
      control,
      isSubmitting,
      setRef,
    }) => {
      const {
        MainStore: {
          applicationStore: { isSmartComponentsShowing },
        },
      } = useStores();

      const {
        field: { onChange, value, name },
        fieldState,
      } = useController({ control, name: props.id, rules });

      const myRef = useRef(null);

      useEffect(() => {
        if (myRef?.current) setRef(myRef.current, props.name);
      }, [props.name]);

      if (!isSmartComponentsShowing) return;

      return (
        <Component
          onChange={onChange}
          value={value}
          name={name}
          ref={myRef}
          key={props.id}
          fieldState={fieldState}
          isSubmitting={isSubmitting}
          options={{}}
        />
      );
    }
  )
);

FormBuilderStepItem.displayName = 'FormBuilderStepItem';

import { DATE_FORMAT } from '@pulse-web-platform-core/constants';
import { useStores } from '@shared/hooks';
import { getRisks, getFranchise } from '@shared/utils';
import { format } from 'date-fns';

import type { GetPriceRequest } from '../queries';

import { DEFAULT_PERIOD } from '../constants';

export const useGetRequestDate = () => {
  const {
    MainStore: {
      initProductStore: {
        initState: { code },
      },
      formStateStore: {
        formState: {
          CarInsuranceParameters,
          CarSearch,
          Drivers,
          PolicyImprovement,
        },
      },
    },
  } = useStores();

  const productVersion = code ?? '';

  const date = CarInsuranceParameters?.period?.startDate ?? new Date();
  const dateBegin = format(date, DATE_FORMAT);

  const contractDuration =
    CarInsuranceParameters?.period?.period ?? DEFAULT_PERIOD;

  const franchise = getFranchise(Number(CarInsuranceParameters?.franchise));

  const price = Number(CarSearch?.marketPrice) || 0;

  const insuranceObjects = [
    {
      makeId: CarSearch?.car?.brand?.value ?? '',
      make: CarSearch?.car?.brand?.label ?? '',
      modelId: CarSearch?.car?.model?.value,
      model: CarSearch?.car?.model?.label,
      year: Number(CarSearch?.car?.manufactureYear?.value) || 0,
      enginePower: Number(CarSearch?.car?.power?.value) || undefined,
      region: CarInsuranceParameters?.region ?? '',
      price,
      stoType: CarInsuranceParameters?.repair ?? '',
      franchise,
    },
  ];

  const minDriverAge = Drivers?.minDriverAge ?? 0;

  const minDriverExperience = Drivers?.minDriverExperience ?? 0;

  const risks = getRisks({ insuranceRisks: PolicyImprovement, amount: price });

  const data: GetPriceRequest = {
    productVersion,
    dateBegin,
    contractDuration,
    insuranceObjects,
    minDriverAge,
    minDriverExperience,
    risks,
  };

  return data;
};

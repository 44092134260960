import { useStores } from '@shared/hooks';
import debounce from 'lodash.debounce';
import { useCallback, useEffect } from 'react';

import type { PartialFormState } from '@shared/types';
import type { UseFormWatch, WatchObserver } from 'react-hook-form';

export const useSynchronizeStore = (
  watch: UseFormWatch<PartialFormState>,
  setFormState: VoidFn<PartialFormState>,
  clearErrors: VoidFn
) => {
  const {
    MainStore: {
      authStore: { setAuthError },
    },
  } = useStores();

  const synchronizeStoreForm = useCallback(
    debounce<WatchObserver<PartialFormState>>((values) => {
      setAuthError(false);
      setFormState(values);
      clearErrors();
    }, 1),
    []
  );

  useEffect(() => {
    const subscription = watch(synchronizeStoreForm);

    return () => subscription.unsubscribe();
  }, [watch]);
};

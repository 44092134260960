import { CheckPolicy } from '@entities/import-smart-components/check-policy';
import { PublicRoutes } from '@pulse-web-platform-core/constants';
import { withMemo } from '@shared/hocs';
import {
  useStores,
  useHandleErrorRequest,
  useHandleNotUniqueEmail,
} from '@shared/hooks';
import {
  getCompletePaymentData,
  useCompletePayment,
  useInitOrder,
} from '@shared/queries';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useEffect, type Ref } from 'react';
import { useNavigate } from 'react-router-dom';

import type { CheckPolicyValues } from '@pulse-smart-components-kit/check-policy';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';

import { Loader } from './components';
import { useGetOptions } from './hooks';
import {
  useQueryUpdateProfile,
  getUpdatedProfile,
  useQuerySubmit,
  useGetRequestData,
} from './queries';
import { schema } from './schema';

export const CheckPolicyAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
          fieldState,
        }: SmartComponentAdapterProps<CheckPolicyValues>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            applicationStore: {
              setDisabledSubmit,
              activeStep,
              retryPayment,
              flowConfig: { maxSteps },
            },
            productStore: {
              isPolicyBanned,
              setIsPolicyBanned,
              setOrderData,
              agentLogin,
              stringifiedSubmitData,
            },
            formStateStore: {
              formState: { PolicyholderForm },
              updateFormValue,
            },
            authStore: { disableForm, profile },
          },
        } = useStores();
        const navigate = useNavigate();

        const updatedProfile = getUpdatedProfile(
          PolicyholderForm,
          profile?.profile
        );

        const {
          status,
          refetch,
          error,
          isSuccess: updateProfileIsSuccess,
          isLoading: updateProfileIsLoading,
        } = useQueryUpdateProfile(updatedProfile);

        useHandleErrorRequest({
          status,
          refetch,
          error,
          isLoading: updateProfileIsLoading,
        });

        useHandleNotUniqueEmail({ error });

        const isSubmitEnabled = !updatedProfile || updateProfileIsSuccess;

        const requestData = useGetRequestData();
        const {
          data: submitData,
          isFetching: submitIsFetching,
          status: submitStatus,
          refetch: refetchSubmit,
          remove: removeSubmit,
          error: submitError,
        } = useQuerySubmit(isSubmitEnabled ? requestData : undefined);

        useHandleErrorRequest({
          status: submitStatus,
          refetch: refetchSubmit,
          error: submitError,
          isLoading: submitIsFetching,
        });

        const { resInit } = useInitOrder(updateFormValue, submitData);

        useCompletePayment(getCompletePaymentData(submitData, resInit));

        useEffect(() => {
          if (
            submitError &&
            submitError.response?.status === 400 &&
            submitError.response?.data?.code === 'CALCULATION_ERROR'
          ) {
            setIsPolicyBanned(true);
            navigate(PublicRoutes.POLICY_BANNED);
          }
        }, [submitError]);

        useEffect(() => {
          if (
            isSubmitEnabled &&
            activeStep === maxSteps &&
            !retryPayment &&
            !agentLogin &&
            (JSON.stringify(requestData) !== stringifiedSubmitData ||
              PolicyholderForm?.email !== profile?.profile.email)
          )
            refetchSubmit();
        }, [activeStep, maxSteps, retryPayment, agentLogin, isSubmitEnabled]);

        useEffect(() => {
          if (submitData) {
            setOrderData(submitData);
          }
        }, [submitData]);

        useEffect(() => {
          setDisabledSubmit(submitIsFetching);

          return () => {
            setDisabledSubmit(false);
          };
        }, [submitIsFetching]);

        useEffect(() => {
          if (isPolicyBanned) removeSubmit();
        }, [isPolicyBanned]);

        const handleAnalyticEventSend = () => {};

        const options = useGetOptions(requestData);

        if (submitIsFetching || updateProfileIsLoading) {
          return <Loader />;
        }

        return (
          <Suspense>
            <CheckPolicy
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              disabled={disableForm}
              fieldState={fieldState}
              formSchema={schema}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  )
);

import i18n from '@app/i18n/config';
import { useEffect } from 'react';

import type { Dictionary } from '@app/themes/types';

export const useDictionary = (
  resTexts: Dictionary | undefined,
  isLoadingTexts: boolean
) => {
  useEffect(() => {
    if (resTexts && resTexts.ru && !isLoadingTexts) {
      Object.keys(resTexts.ru).forEach((item) => {
        if (resTexts.ru[item]) {
          i18n.addResourceBundle('ru', item, resTexts.ru[item], true, true);
        }
      });
    }
  }, [resTexts, isLoadingTexts]);
};

import { useHandleErrorRetry } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import type { AuthBoxType } from './types';

import { AuthBoxAnalyticEvent } from './constants';
import {
  useHandleGetProfile,
  useHandleRegistration,
  useHandleAuthentication,
  useHandleConfirmAuthentication,
} from './hooks';

export const AuthBox = observer(
  ({
    updateFormValue,
    setFormValueError,
    registrationData,
    onAnalyticEventSend,
    setIsRegistration,
  }: AuthBoxType) => {
    // authentication
    const {
      resAuthentication,
      isLoadingAuthentication,
      refetchAuthentication,
    } = useHandleAuthentication(setFormValueError, onAnalyticEventSend);

    // confirm-authentication
    const { removeConfirmAuthentication, refetchConfirmAuthentication } =
      useHandleConfirmAuthentication(
        isLoadingAuthentication,
        resAuthentication
      );

    // registration
    const { resRegistration, refetchRegistration, isLoadingRegistration } =
      useHandleRegistration(
        registrationData,
        updateFormValue,
        setFormValueError
      );

    // get-profile
    const { isLoadingGetProfile, resGetProfile, refetchGetProfile } =
      useHandleGetProfile(
        updateFormValue,
        refetchRegistration,
        removeConfirmAuthentication
      );

    // analytics
    useEffect(() => {
      if (!isLoadingGetProfile && !isLoadingRegistration) {
        if (resRegistration && resGetProfile) {
          onAnalyticEventSend &&
            onAnalyticEventSend(AuthBoxAnalyticEvent.ON_REGISTRATION_SUCCESS);
        }

        if (!resRegistration && resGetProfile) {
          onAnalyticEventSend &&
            onAnalyticEventSend(AuthBoxAnalyticEvent.ON_SIGNUP_SUCCESS);
        }
      }
    }, [
      resRegistration,
      resGetProfile,
      isLoadingGetProfile,
      isLoadingRegistration,
    ]);

    const selectRefetch = (index: string) => {
      switch (index) {
        case 'REG':
          return refetchRegistration().then(() => {
            setIsRegistration(true);
          });
        case 'AUTHENTICATION':
          return refetchAuthentication();
        case 'GET_PROFILE':
          return refetchGetProfile();
        case 'CONFIRMAUTH':
          return refetchConfirmAuthentication();
        default:
          return;
      }
    };

    useHandleErrorRetry(selectRefetch);

    return null;
  }
);

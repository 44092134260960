import {
  PTS_REG_EXP,
  STS_REG_EXP,
  EPTS_REG_EXP,
} from '@pulse-smart-components-kit/common';
import { CarDocument } from '@pulse-web-platform-core/constants';
import { parsingDocument } from '@shared/utils';

export const getDataDocument = (
  document: string,
  documentType: CarDocument
) => {
  switch (documentType) {
    case CarDocument.STS:
      return parsingDocument(document, STS_REG_EXP);

    case CarDocument.PTS:
      return parsingDocument(document, PTS_REG_EXP);

    case CarDocument.EPTS:
      return parsingDocument(document, EPTS_REG_EXP);

    default:
      return ['', ''];
  }
};

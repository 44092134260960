import { SmartComponentName } from '@product-config/constants';
import { useStores } from '@shared/hooks';
import { addDays } from 'date-fns';
import { useEffect, useMemo } from 'react';

import {
  getDefaultFranchiseValue,
  getDefaultValues,
  getMinCountDays,
} from '../utils';

const TODAY_DATE = new Date();

export const useSetDefaultValue = (region: string | undefined) => {
  const {
    MainStore: {
      formStateStore: {
        formState: { CarInsuranceParameters: InsuranceParametersStore },
        updateFormValue,
      },
      initProductStore: {
        initState: { extraParams },
      },
    },
  } = useStores();

  const minDate = useMemo(() => {
    const minCountDays = getMinCountDays(extraParams);
    return addDays(TODAY_DATE, minCountDays);
  }, [extraParams]);

  const franchiseDefaultValue = useMemo(() => {
    return getDefaultFranchiseValue(extraParams);
  }, [extraParams]);

  useEffect(() => {
    const defaultValues = getDefaultValues(extraParams, minDate, region);

    updateFormValue(SmartComponentName.CAR_INSURANCE_PARAMETERS, {
      ...InsuranceParametersStore,
      ...defaultValues,
      franchise: franchiseDefaultValue,
    });
  }, [franchiseDefaultValue, extraParams, minDate, region]);
};

import { DATE_FORMAT } from '@pulse-web-platform-core/constants';
import { NORMALIZE_LICENSE_PLATE_REG_EXP } from '@shared/constants/reg-exps';
import { getFranchise } from '@shared/utils';
import { format } from 'date-fns';

import type { InsuranceObject } from '../types';
import type { CarDocumentValues } from '@pulse-smart-components-kit/car-document';
import type { CarInsuranceParametersValues } from '@pulse-smart-components-kit/car-insurance-parameters';
import type { CarSearchValues } from '@pulse-smart-components-kit/car-search';
import type { CarDocument } from '@pulse-web-platform-core/constants';

import { getDataDocument } from './get-data-document';
import { OBJECT_TYPE } from '../constants';

export interface Params {
  carSearch?: CarSearchValues;
  insuranceParameters?: CarInsuranceParametersValues;
  carDocument?: CarDocumentValues;
}

export const getInsuranceObject = ({
  carSearch,
  insuranceParameters,
  carDocument,
}: Params): InsuranceObject => {
  const [series, number] = getDataDocument(
    carDocument?.documentNumber ?? '',
    (carDocument?.document ?? '') as CarDocument
  );
  const document = {
    documentType: carDocument?.document ?? '',
    vehicleDocSeries: series.replace(' ', ''),
    vehicleDocNumber: number,
    vehicleDocIssueDate: format(
      carDocument?.documentDate ?? new Date(),
      DATE_FORMAT
    ),
  };
  return {
    objectType: OBJECT_TYPE,
    licensePlate:
      carSearch?.carNumber?.replaceAll(NORMALIZE_LICENSE_PLATE_REG_EXP, '') ??
      '',
    vin: carSearch?.vin ?? '',
    make: carSearch?.car?.brand?.label ?? '',
    makeId: carSearch?.car?.brand?.value ?? '',
    model: carSearch?.car?.model?.label ?? '',
    modelId: carSearch?.car?.model?.value ?? '',
    year: Number(carSearch?.car?.manufactureYear?.value ?? 0),
    enginePower: Number(carSearch?.car?.power?.value ?? 0),
    mileage: carSearch?.mileage ?? 0,
    price: Number(carSearch?.marketPrice) || 0,
    region: insuranceParameters?.region ?? '',
    isCredit: Boolean(carSearch?.carOnCredit),
    stoType: insuranceParameters?.repair ?? '',
    vehicleDocument: document,
    franchise: getFranchise(Number(insuranceParameters?.franchise ?? 0)),
  };
};

import { Endpoints, PRODUCT_CODE } from '@shared/constants';
import { useStores } from '@shared/hooks';
import { fetchData } from '@shared/utils';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

import type { ResponseError } from '@pulse-web-platform-core/types';
import type { InsuranceProduct } from '@shared/types';

export const useQueryProduct = () => {
  const {
    MainStore: {
      initProductStore: { initState, setInitState },
    },
  } = useStores();

  const query = useQuery<InsuranceProduct, ResponseError>(
    ['get-insurance-product'],
    fetchData<InsuranceProduct>({
      url: Endpoints.GET_INSURANCE_PRODUCT,
      method: 'POST',
      data: { productCode: PRODUCT_CODE },
    }),
    { cacheTime: Infinity, staleTime: Infinity }
  );

  const { isLoading, data } = query;

  useEffect(() => {
    if (!isLoading && data) {
      setInitState({ ...initState, ...data });
    }
  }, [isLoading, data]);

  return query;
};

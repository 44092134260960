import { axiosInstance } from '@shared/utils';
import { useQueries } from 'react-query';

import type { UseMultipleDownloadPdf } from './use-handle-download-pdf.types';

import { getQueryKey } from './use-handle-download-pdf.utils';

export const useMultipleDownloadPdf: UseMultipleDownloadPdf = ({
  documentsList,
  accessToken,
}) =>
  useQueries(
    documentsList?.map((doc) => ({
      queryKey: [getQueryKey(doc.clientDocument)],
      queryFn: () =>
        axiosInstance({
          url: `/v1/file/${doc.clientDocument}`,
          method: 'POST',
          data: {},
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/pdf',
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'arraybuffer',
        }),
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
    })) || []
  );

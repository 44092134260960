import { useEffect, useState } from 'react';

import type {
  FlowConfigLocal,
  ProductConfig,
  ProductStep,
  StepConfig,
  GlobalVariables,
} from '@product-config/index';
import type { HeaderStep } from '@pulse-smart-components-kit/header-base';
import type { FormBuilderStructure, SmartComponent } from '@shared/types';

import {
  initState,
  getSmartComponents,
  getCommonComponents,
  mergeAgentHeaderConfig,
} from './use-app-configs.helpers';
import { useStores } from '../use-stores';

export const useAppConfigs = <T extends GlobalVariables>(
  defaultConfig: ProductConfig<T>
) => {
  const {
    MainStore: {
      applicationStore: { setFlowConfig },
      productStore: { agentLogin },
      themesStore: { appConfig },
    },
  } = useStores();

  const [components, setComponents] = useState<FormBuilderStructure>(initState);
  const [flowConfigLocal, setFlowConfigLocal] = useState<
    Partial<FlowConfigLocal>
  >({});
  const config = appConfig || defaultConfig;

  // Место для мёрджа конфигов: дефолтного, темы, персонализации и входных данных с маркетинговой страницы.
  useEffect(() => {
    const body: SmartComponent[] = [];
    const headerConfig: HeaderStep[] = [];
    const stepConfig: StepConfig[] = [];

    config.flow.forEach((flow: ProductStep, flowIndex: number) => {
      const localStep = getSmartComponents(flow, flowIndex);
      body.push(...localStep.components);
      headerConfig.push(localStep.header);
      stepConfig.push(localStep.steps);
    });

    const smartComponents: FormBuilderStructure = {
      header: getCommonComponents(config.header),
      footer: getCommonComponents(config.footer),
      submit: getCommonComponents(config.submit),
      body,
    };

    setComponents(smartComponents);
    setFlowConfigLocal({
      headerConfig: mergeAgentHeaderConfig(headerConfig, agentLogin),
      stepConfig,
    });
  }, [config]);

  useEffect(() => {
    setFlowConfig({
      ...config.globalVariables,
      maxSteps: config.flow.length,
      ...flowConfigLocal,
    });
  }, [config.globalVariables, flowConfigLocal]);

  return components;
};

import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { CarInsuranceParameters } from '@entities/import-smart-components/car-insurance-parameters';
import { CarInsuranceParametersAnalyticEvent } from '@pulse-smart-components-kit/car-insurance-parameters';
import { Spinner } from '@pulse-web-ui/spinner';
import { FallbackSkeleton } from '@shared/components';
import { withMemo } from '@shared/hocs';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { forwardRef, type Ref, Suspense, useEffect, useState } from 'react';

import type {
  CarInsuranceParametersValues,
  CarInsuranceParametersOptions,
} from '@pulse-smart-components-kit/car-insurance-parameters';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import type { AnalyticEventsMap } from '@pulse-web-platform-core/types';

import {
  useGetFranchises,
  useGetPeriod,
  useGetRegions,
  useGetRepairs,
  useSetDefaultValue,
} from './hooks';
import {
  useQueryVehicleRegion,
  useQueryInsuranceProductPeriod,
  useQueryVehicleRepairType,
  useQueryFranchise,
  useQueryRegion,
} from './queries';

const analyticEventsMap: AnalyticEventsMap<CarInsuranceParametersAnalyticEvent> =
  {
    [CarInsuranceParametersAnalyticEvent.ON_REGION_SELECTED]: {
      name: analyticEvents.regionSelected,
    },
    [CarInsuranceParametersAnalyticEvent.ON_DATE_SELECTED]: {
      name: analyticEvents.dateSelected,
    },
    [CarInsuranceParametersAnalyticEvent.ON_FRANCHISE_SELECTED]: {
      name: analyticEvents.franchiseSelected,
    },
  };

export const CarInsuranceParametersAdapter = withMemo(
  observer(
    forwardRef(
      (
        {
          value,
          onChange,
          isSubmitting,
        }: SmartComponentAdapterProps<Partial<CarInsuranceParametersValues>>,
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            formStateStore: {
              formState: { CarSearch, CarNumber },
            },
            initProductStore: {
              initState: { code },
            },
          },
        } = useStores();

        const [isLoading, setIsLoading] = useState(true);

        const carNumber = CarSearch?.carNumber ?? CarNumber?.carNumber;

        const { data: dataVehicleRegion, isLoading: isLoadingVehicleRegion } =
          useQueryVehicleRegion(code);
        const { data: dataRepair, isLoading: isLoadingRepair } =
          useQueryVehicleRepairType(code);
        const { data: dataPeriod, isLoading: isLoadingPeriod } =
          useQueryInsuranceProductPeriod(code);
        const { data: dataFranchise, isLoading: isLoadingFranchise } =
          useQueryFranchise(code);
        const { data: dataRegion, isLoading: isLoadingRegion } = useQueryRegion(
          {
            carNumber: carNumber,
            code,
          }
        );

        const isLoadings =
          isLoadingVehicleRegion ||
          isLoadingRepair ||
          isLoadingPeriod ||
          isLoadingFranchise ||
          isLoadingRegion;

        useEffect(() => {
          setIsLoading(isLoadings);
        }, [isLoadings]);

        useSetDefaultValue(dataRegion?.region);

        const regions = useGetRegions(dataVehicleRegion?.vehicleRegions);

        const repairs = useGetRepairs(dataRepair?.vehicleRepairTypes);

        const period = useGetPeriod(dataPeriod?.insuranceProductPeriods);

        const franchises = useGetFranchises(dataFranchise?.franchises);

        const options: CarInsuranceParametersOptions = {
          regions,
          repairs,
          period,
          franchises,
        };

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<CarInsuranceParametersAnalyticEvent>(
            analyticEventsMap
          );

        return (
          <Suspense
            fallback={
              <FallbackSkeleton height={274}>
                <Spinner />
              </FallbackSkeleton>
            }
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <CarInsuranceParameters
                value={value}
                onChange={onChange}
                isSubmitting={isSubmitting}
                options={options}
                ref={forwardRef}
                onAnalyticEventSend={handleAnalyticEventSend}
              />
            )}
          </Suspense>
        );
      }
    )
  )
);

CarInsuranceParametersAdapter.displayName = 'CarInsuranceParametersAdapter';
